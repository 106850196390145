import React from 'react';
import { Anchor, Text } from '../../../../Text';
import styles from '../header.module.sass';
import {
  riverProfitShips,
  riverBestReviewsShips,
  seaProfitShips,
  seaBestReviewsShips,
} from './arraysHeaderShips';
import { Icon } from '../../../../Icon';
import { YaAndGaHeader } from '../../../../../lib/YaAndGaHelper';
import { IconSVG } from '../../../../SpriteSVG';

const HeaderShips = ({ isSea, shipsSea }) => {
  const profitShips = isSea || shipsSea ? seaProfitShips : riverProfitShips;
  const bestReviewsShips =
    isSea || shipsSea ? seaBestReviewsShips : riverBestReviewsShips;

  return (
    <div className={styles.dropDownMenu}>
      <div className="container">
        <div className={styles.dropDownMenuWrapper}>
          <div>
            <div className={styles.textWrapper}>
              <Text variant="heading-3">Выгодно</Text>
            </div>

            <ul className={styles.dropDownMenuFeatures}>
              {profitShips.map((ship, key) => (
                <li
                  key={key}
                  className={styles.dropDownMenuFeature}
                  itemProp="name"
                >
                  <Anchor
                    href={ship.linkProfitShip}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      YaAndGaHeader(
                        'click_any_ship_in_column_profit_mega_menu',
                      );
                    }}
                    itemProp="url"
                  >
                    {ship.nameProfitShip}
                  </Anchor>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <div className={styles.textWrapper}>
              <Text variant="heading-3">{isSea ? 'Популярные лайнеры': 'Лучшие отзывы'}</Text>
            </div>
            <ul className={styles.dropDownMenuFeatures}>
              {bestReviewsShips.map((bestRivShip, key) => (
                <li key={key} className={styles.dropDownMenuFeature}>
                  <Anchor
                    href={bestRivShip.linkBestRevShip}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      YaAndGaHeader(
                        'click_any_ship_in_column_best_review_mega_menu',
                      );
                    }}
                  >
                    {bestRivShip.nameBestRevShip}
                  </Anchor>
                </li>
              ))}
            </ul>
          </div>
          <div>
              <>
                <div className={styles.infoWrapper}>
                  <Anchor
                    href={isSea ? '/liners' : '/ships'}
                    target="_blank"
                    color="primaryColor"
                    rel="noreferrer"
                    variant="heading-3"
                    onClick={() => {
                      YaAndGaHeader('click_all_ships_in_mega_menu');
                    }}
                  >
                    Все {isSea ? 'лайнеры' : 'теплоходы'}{' '}
                  </Anchor>
                  <Icon
                    leftGap={10}
                    icon={
                      <IconSVG
                        name={'common/big-right'}
                        width={16}
                        height={24}
                        title="стрелка вправо"
                      />
                    }
                    title="стрелка вправо"
                  />
                </div>
                <div>
                  <Text variant="text-1">
                    Укажите свои пожелания чтобы найти <br />
                    подходящий теплоход
                  </Text>
                </div>
              </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderShips;
